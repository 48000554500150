/* eslint-disable jsx-a11y/iframe-has-title */
import { IonContent, IonFooter, IonToolbar } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import Box from '@material-ui/core/Box';

import firebase from '../hooks/firebase';
import Copyright from '../components/Copyright';
import { AuthContext } from '../hooks/Auth';

const Video = props => {
  const { currentUser } = useContext(AuthContext);
  const [events, setEvents] = useState([]);
  useEffect(() => {
    async function getEvents() {
      let isPurchasedVideo = false;
      await firebase.getEvents().then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc !== null && doc.exists) {
            events.push(doc.data());
            if (isPurchased(doc.data())) {
              isPurchasedVideo = true;
            }
          }
          setEvents(events);
        });
      });
      if (!isPurchasedVideo) {
        window.location.href = '/NotVideo';
      }
    }
    if (currentUser) {
      getEvents();
    } else if (currentUser === null) {
      props.history.push('/Home');
      return;
    }
  }, [currentUser]);

  function isPurchased(data) {
    if (data.メールアドレス === firebase.currentUser().email) {
      if (data.入金ステータス_0入金待ち_1入金済み_2返金済み === '1') {
        return true;
      }
    }
    return false;
  }

  return (
    <IonContent class="video-playlist-content">
      <div>
        <iframe
          // src="https://player.vimeo.com/video/448020211?playsinline=0"
          src="https://vimeo.com/event/1127209/embed/36ebf4ae1f"
          width="100%"
          height="500px"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            top: 10,
            left: 10,
            right: 10,
            bottom: 10,
          }}
        />
      </div>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <Box mt={8}>
            <Copyright />
          </Box>
        </IonToolbar>
      </IonFooter>
    </IonContent>
  );
};
export default Video;
