import React from 'react';
import ReactMarkdown from 'react-markdown';
import Container from '@material-ui/core/Container';
import { IonContent } from '@ionic/react';

export const Privacy = () => {
  return (
    <IonContent>
      <Container>
        <ReactMarkdown>
          {`
プライバシーポリシー

株式会社ポケットコンサート（以下「当社」といいます。）は、当社の提供するサービス（以下「本サービス」といいます。）における、ユーザーについての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。

1 　収集する利用者情報及び収集方法

本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。

本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。

① ユーザーからご提供いただく情報

本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。

・氏名、生年月日、性別、職業等プロフィールに関する情報

・メールアドレス、電話番号、住所等連絡先に関する情報

・クレジットカード情報、銀行口座情報、電子マネー情報等決済手段に関する情報

・入力フォームその他当社が定める方法を通じてユーザーが入力または送信する情報

② ユーザーが本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報

ユーザーが、本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。

・当該外部サービスでユーザーが利用する ID

・その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報

③ ユーザーが本サービスを利用するにあたって、当社が収集する情報

当社は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。

・リファラ

・IP アドレス

・サーバーアクセスログに関する情報

・Cookie、ADID、IDFA その他の識別子

④ ユーザーが本サービスを利用するにあたって、当社がユーザーの個別同意に基づいて収集する情報

当社は、ユーザーが 3⑴ に定める方法により個別に同意した場合、当社は以下の情報を利用中の端末から収集します。

・位置情報

2 　利用目的

本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。

① 本サービスに関する登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、利用料金の決済計算等本サービスの提供、維持、保護及び改善のため

② ユーザーのトラフィック測定及び行動測定のため

③ 広告の配信、表示及び効果測定のため

④ 本サービスに関するご案内、お問い合わせ等への対応のため

⑤ 本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため

⑥ 本サービスに関する規約等の変更などを通知するため

3 　通知・公表または同意取得の方法、利用中止要請の方法

⑴ 以下の利用者情報については、その収集が行われる前にユーザーの同意を得るものとします。

・位置情報

⑵ ユーザーは、本サービスの所定の設定を行うことにより、利用者情報の全部または一部についてその収集又は利用の停止を求めることができ、この場合、当社は速やかに、当社の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集または利用が本サービスの前提となるため、当社所定の方法により本サービスを退会した場合に限り、当社はその収集又は利用を停止します。

4 　外部送信、第三者提供、情報収集モジュールの有無

⑴ 本サービスでは、以下の提携先が、ユーザーの端末に Cookie を保存し、これを利用して利用者情報を蓄積及び利用している場合があります。

① 提携先：株式会社メタップスペイメント

② 上記提携先のプライバシーポリシーの URL：https://www.metaps-payment.com/etc/agreement/eventpay_user_agreement.html

③ 上記提携先のオプトアウト（無効化）URL：無し

⑵ 本サービスには以下の情報収集モジュールが組み込まれています。これに伴い、以下のとおり情報収集モジュール提供者（日本国外にある者を含みます。）への利用者情報の提供を行います。

① 情報収集モジュールの名称：無し

② 情報収集モジュールの提供者：無し

③ 提供される利用者情報の項目：

氏名、フリガナ、住所、電話番号、電子メールアドレス、アンケート項目への回答内容及び、お支払方法等申し込みに際して入力した情報（但し、クレジットカードによる支払を選択した場合に入力したカード番号、カード有効期間、セキュリティコード、カード名義を除く）並びに、イベント購入代金のお支払いの状況

④ 提供の手段・方法：

お客様登録時、イベント購入時の入力による直接取得

⑤ 上記提供者における利用目的：

イベント等の主催者が提供する興行・商品・サービス等の案内のため

イベント等の会場に入場する際の本人確認のため

イベント等の中止・延期・内容変更等の連絡、それに伴う払戻し業務（払戻しがある場合）を行うため

⑥ 上記提供者における第三者提供の有無：有

⑦ 上記提供者のプライバシーポリシーの URL：

https://www.metaps-payment.com/etc/agreement/eventpay_user_agreement.html

5 　第三者提供

当社は、利用者情報のうち、個人情報については、あらかじめユーザーの同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。ただし、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。

① 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合

② 合併その他の事由による事業の承継に伴って個人情報が提供される場合

③ 第 4 項の定めに従って、提携先または情報収集モジュール提供者へ個人情報が提供される場合

④ 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合

⑤ その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合

6 　共同利用

当社は、本サービスの提供のため、以下のとおりユーザーの個人情報を本サービス共同運営企業において共同利用します。

① 共同して利用される個人情報の項目　第 1 項と同じ

② 共同して利用する者の範囲　　　　　 Langdemy 合同会社及び株式会社エスピープロダクツ及び有限会社メロネスト

③ 共同して利用する者の利用目的　　　第 2 項と同じ

④ 個人情報の管理について責任を有する者の氏名または名称

Langdemy 合同会社　個人情報管理責任者　氏名　遠藤 憲

7 　個人情報の開示

当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。ただし、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1 件あたり 1,000 円）を頂戴しておりますので、あらかじめ御了承ください。

8 　個人情報の訂正及び利用停止等

⑴ 当社は、ユーザーから、① 個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び ② あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。

⑵ 当社は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。

⑶ 個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、⑴ および ⑵ の規定は適用されません。

9 　お問い合わせ窓口

ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。

住所：東京都港区南青山 2-2-15 ウィン青山 942

Langdemy 合同会社

個人情報取扱責任者： 遠藤 憲

連絡先：07083176182

ポケットコンサート LINE 公式アカウントお問い合わせ窓口：<https://line.me/ti/p/@000eurwp>

10 　プライバシーポリシーの変更手続

当社は、必要に応じて、本ポリシーを変更します。ただし、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を当社のウェブサイト上での表示その他の適切な方法により周知し、またはユーザーに通知します。

`}
        </ReactMarkdown>
      </Container>
    </IonContent>
  );
};
