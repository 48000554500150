import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout_startmenu from './ProductHeroLayout_startmenu';
import { CardMedia } from '@material-ui/core';
import { AuthContext } from '../../../hooks/Auth';
import Grid from '@material-ui/core/Grid';
import { blue } from '@material-ui/core/colors';

// const backgroundImage = '';

const styles = theme => ({
  background: {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#87cefa', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  body2: {
    marginBottom: theme.spacing(2),
    minWidth: 200,
  },
  buttonsignUp: {
    minWidth: 200,
    marginBottom: 5,
    marginTop: theme.spacing(1),
    backgroundColor: '#0000cd',
    color: '#ffffff',
  },
  more: {
    marginTop: theme.spacing(1),
  },
});

function ProductHero(props) {
  const { classes } = props;
  const { currentUser } = useContext(AuthContext);
  const textsignup = '初めての方はこちらから\nユーザ登録をお願いします。';

  const textlogin =
    'ユーザー登録完了している方は\nこちらからログインをお願いします。';

  const textabout =
    '「ポケットコンサート」では、コロナ禍でコンサートやイベントが中止になり、\n音楽活動ができない様々なアーティストを応援しています。';

  return (
    <ProductHeroLayout_startmenu backgroundClassName={classes.background}>
      <Typography
        component={'div'}
        color="inherit"
        align="center"
        variant="h4"
        marked="center"
      >
        ポケットコンサート公式サイト
      </Typography>
      <Typography
        component={'div'}
        color="inherit"
        align="center"
        variant="h5"
        className={classes.h5}
      >
        「ポケットコンサート」は、プロの音楽家の演奏をご自宅で簡単に鑑賞できる有料動画配信サービスです。
      </Typography>
      <Typography
        component={'div'}
        color="inherit"
        align="center"
        variant="h5"
        className={classes.h5}
      >
        {textabout.split('\n').map((t, i) => {
          return <div key={i}>{t}</div>;
        })}
      </Typography>

      <img
        src="/assets/images/pokeconlogo.png"
        alt="wonder"
        width="200"
        height="200"
      />
      <Button
        color="default"
        variant="contained"
        size="large"
        className={classes.buttonsignUp}
        component="a"
        href="/SignUp"
      >
        ユーザー登録 ＞
      </Button>
      <Typography
        component={'div'}
        color="inherit"
        align="justify"
        variant="body2"
        className={classes.body2}
      >
        {textsignup.split('\n').map((t, i) => {
          return <div key={i}>{t}</div>;
        })}
      </Typography>
      {/* </Grid> */}

      {/* <Grid item xs={2}> */}
      <Button
        color="default"
        variant="contained"
        size="large"
        className={classes.buttonsignUp}
        component="a"
        href="/Login"
      >
        ログイン  ＞
      </Button>
      <Typography
        component={'div'}
        color="inherit"
        align="justify"
        variant="body2"
        className={classes.body2}
      >
        {textlogin.split('\n').map((t, i) => {
          return <div key={i}>{t}</div>;
        })}
      </Typography>
      <Button
        color="default"
        variant="contained"
        size="large"
        className={classes.buttonsignUp}
        component="a"
        href="/Manual"
      >
        マニュアル ＞
      </Button>
      {/* </Grid>
      </Grid> */}
    </ProductHeroLayout_startmenu>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
