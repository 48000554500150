import { User } from '@firebase/auth-types';
import React, { createContext, useEffect, useState } from 'react';
import firebase from 'firebase';
// import auth from "./firebase";

type AuthContextProps = {
  currentUser: User | null | undefined;
};

const AuthContext = createContext<AuthContextProps>({ currentUser: undefined });

const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null | undefined>(
    undefined,
  );

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setCurrentUser(user);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser: currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// export function createCtx<IAuthContext>() {
//   const ctx = React.createContext<IAuthContext | undefined>(undefined);
//   function useCtx() {
//     const c = React.useContext(ctx);
//     if (!c) throw new Error('useCtx must be inside a Provider with a value');
//     return c;
//   }
//   return [useCtx, ctx.Provider] as const;
// }

export { AuthContext, AuthProvider };
