import React from 'react';
import ReactMarkdown from 'react-markdown';
import Container from '@material-ui/core/Container';
import { IonContent } from '@ionic/react';

export const Law = () => {
  return (
    <IonContent>
      <Container>
        <ReactMarkdown>
          {`
特定商取引法に基づく表記

会社名
Langdemy(ランデミー)合同会社

事業者の名称
ポケットコンサート

事業者の所在地
郵便番号 ：107-0062

住所 ：東京都港区南青山2-2-15 ウィン青山942

事業者の連絡先
電話番号 ： 070-8317-6182

営業時間：平日11:00-16:00
定休日：土日祝日

※上記営業時間帯に電話をお受けできない場合がございます。その場合は、大変お手数をおかけいたしますが、ポケットコンサートLINE公式アカウントにて、ご用件をお伝えいただきますと幸いです。

ポケットコンサートLINE公式アカウントのURLはこちらになります。
https://line.me/ti/p/@000eurwp

販売価格について
販売価格は、決済代行会社（イベントペイ）で表示された金額と致します。

代金（対価）の支払方法と時期
支払方法：クレジットカード決済、コンビニ決済、Pay-easyがご利用頂けます。
支払時期：商品注文確定時でお支払いが確定致します。

返品についての特約に関する事項
商品の特性上、返品には応じることが出来かねますので、ご了承ください。

`}
        </ReactMarkdown>
      </Container>
    </IonContent>
  );
};
