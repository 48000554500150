import {
  IonButtons,
  IonContent,
  IonFooter,
  IonToast,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import firebase from '../hooks/firebase';
import Copyright from '../components/Copyright';
import { AuthContext } from '../hooks/Auth';
import { CheckBox } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  home: {
    //marginTop: theme.spacing(1),
    width: 120,
  },
}));

const SignUp = (props: any) => {
  // const { currentUser } = useContext(AuthContext);
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);
  const [isComposed, setIsComposed] = useState(false);
  const [toastIsShown, setToastIsShown] = useState(false);
  const showToast = () => {
    setToastIsShown(true);
  };
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const disabled =
      email === '' ||
      password === '' ||
      firstName === '' ||
      //lastName === '' ||
      !checked;
    // || checkbox === on;
    setDisabled(disabled);
    // if logged in, redirect to home
    // currentUser && props.history.push('/Teachers');
  }, [email, password, firstName, lastName, checked]);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>ポケットコンサート公式サイト</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">ポケットコンサート公式サイト</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component={'div'} variant="h5">
              ユーザ登録
            </Typography>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="氏名"
                    autoFocus
                    onChange={(e: any) => setFirstName(e.target.value)}
                    onKeyDown={(e: any) => {
                      if (isComposed) return;

                      if (e.key === 'Enter') {
                        setFirstName(e.target.value);
                        e.preventDefault();
                      }
                    }}
                    onCompositionStart={() => setIsComposed(true)}
                    onCompositionEnd={() => setIsComposed(false)}
                  />
                </Grid>
{/*                 <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                    onChange={(e: any) => setLastName(e.target.value)}
                    onKeyDown={(e: any) => {
                      if (isComposed) return;

                      if (e.key === 'Enter') {
                        setLastName(e.target.value);
                        e.preventDefault();
                      }
                    }}
                    onCompositionStart={() => setIsComposed(true)}
                    onCompositionEnd={() => setIsComposed(false)}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="メールアドレス"
                    name="email"
                    autoComplete="email"
                    onChange={(e: any) => setEmail(e.target.value)}
                    onKeyDown={(e: any) => {
                      if (isComposed) return;

                      if (e.key === 'Enter') {
                        setEmail(e.target.value);
                        e.preventDefault();
                      }
                    }}
                    onCompositionStart={() => setIsComposed(true)}
                    onCompositionEnd={() => setIsComposed(false)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="パスワード"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e: any) => setPassword(e.target.value)}
                    onKeyDown={(e: any) => {
                      if (isComposed) return;

                      if (e.key === 'Enter') {
                        setPassword(e.target.value);
                        e.preventDefault();
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container justify="flex-start" alignItems="baseline">
                <Grid item></Grid>
              </Grid>
              <FormControlLabel
                control={
                  <>
                    <Checkbox
                      color="primary"
                      checked={checked}
                      onChange={handleChange}
                    />
                    <Link
                      href="/Terms"
                      variant="body1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      利用規約
                    </Link>
                  </>
                }
                label="に同意する"
              />
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={disabled}
                onClick={() => {
                  firebase
                    .signUp(email, password)
                    .then(res => {
                      // console.dir(res);
                      const displayName = lastName + firstName;
                      const updateObj = {
                        displayName: displayName,
                        photoURL: null,
                      };
                      firebase.signUpFirestoreUser(res.user).then(response => {
                        // console.dir(response);
                        firebase.updateProfile(res.user, updateObj).then(() => {
                          firebase
                            .updateFirestoreUser(response, updateObj)
                            .then(() => {
                              props.history.push('/about');
                            });
                        });
                      });
                    })
                    .catch(e => {
                      // console.dir(e.code);
                      switch (e.code) {
                        case 'auth/email-already-in-use':
                          setToastIsShown(true);
                          setErrorMessage(
                            'ユーザー登録が既にされているか、もしくは入力内容に不正な文字が入ったため、登録失敗しました。',
                          );
                          break;
                        default:
                          setToastIsShown(true);
                          setErrorMessage(
                            '不明なエラーが発生しました。画面をリフレッシュしてもう一度お試しください。',
                          );
                          break;
                      }
                      // console.dir(e);
                    });
                }}
              >
                ユーザ登録
              </Button>
              <Button
                type="button"
                //fullWidth
                variant="contained"
                color="primary"
                className={classes.home}
                onClick={() => {
                  props.history.push('/Home');
                }}
              >
                ホームへ戻る
              </Button>
              <Grid container >
                <Grid item >
                  <Link href="/login" variant="body2">
                    既にアカウントをお持ちの方は、ログイン
                  </Link>
                </Grid>
              </Grid>
            </form>
            <IonToast
              isOpen={toastIsShown}
              onDidDismiss={() => setToastIsShown(false)}
              message={errorMessage}
              duration={3000}
            />
          </div>
        </Container>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <Box mt={3}>
            <Copyright />
          </Box>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default SignUp;
