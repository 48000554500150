import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import { CardMedia } from '@material-ui/core';
import { AuthContext } from '../../../hooks/Auth';
import firebase from '../../../hooks/firebase';

const backgroundImage = '';
// const backgroundImage = '/assets/images/pokeconlogo.png';
const styles = theme => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    //backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundColor: '#87cefa', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 220,
    marginBottom: 20,
    backgroundColor: '#0000cd',
    color: '#ffffff',
  },
  h4: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  body2: {
    marginBottom: theme.spacing(2),
  },
  buttonticket: {
    minWidth: 220,
    marginBottom: 5,
    backgroundColor: '#0000cd',
    color: '#ffffff',
  },
  more: {
    marginTop: theme.spacing(1),
  },
});

function ProductHero(props) {
  const { classes } = props;
  // const { classes } = styles();
  const { currentUser } = useContext(AuthContext);
  const [events, setEvents] = useState([]);
  const text =
    '※チケットを購入する際に登録するEmailアドレスは\n本サイトで登録したEmailアドレスを\n使用してください。';
  const lineText =
    '※ご不明点等がある場合は、\nLINE公式アカウントまでお問い合わせください。';

  // const home = window.location.href = '/Home'

  useEffect(() => {
    // !currentUser && home
    async function getEvents() {
      await firebase.getEvents().then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc !== null && doc.exists) {
            events.push(doc.data());
          }
          // setIsSkeleton(false);
          setEvents(events);
        });
      });
    }
    getEvents();
  }, []);

  function isPurchased() {
    for (let key in events) {
      if (events[key].メールアドレス === firebase.currentUser().email) {
        if (events[key].入金ステータス_0入金待ち_1入金済み_2返金済み === '1') {
          window.location.href = '/Video';
          return;
        }
      }
    }
    window.location.href = '/NotVideo';
  }

  function handleClick(e) {
    e.preventDefault();
    isPurchased();
  }

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      <Typography
        component={'div'}
        color="inherit"
        align="center"
        variant="h4"
        className={classes.h4}
      >
        ポケットコンサートにようこそ！
      </Typography>
      {/* {!currentUser ? ( */}
      <img
        src="/assets/images/pokeconlogo.png"
        alt="wonder"
        width="200"
        height="200"
      />
      <Button
        color="default"
        variant="contained"
        size="large"
        className={classes.buttonticket}
        component="a"
        href="https://eventpay.jp/list_view/?shop_code=2637482384281150"
      >
        チケット購入  ＞
      </Button>
      <Typography
        component={'div'}
        color="inherit"
        align="justify"
        variant="body2"
        className={classes.body2}
      >
        {text.split('\n').map((t, i) => {
          return <div key={i}>{t}</div>;
        })}
      </Typography>
      <Button
        color="default"
        variant="contained"
        size="large"
        className={classes.button}
        component="a"
        // href="/Video"
        onClick={handleClick}
      >
        動画視聴  ＞
      </Button>
      <Button
        color="default"
        variant="contained"
        size="large"
        className={classes.buttonticket}
        component="a"
        href="https://line.me/ti/p/@000eurwp"
        target="_blank"
        rel="noopener noreferrer"
      >
        LINE問い合わせ  ＞
      </Button>
      <Typography
        component={'div'}
        color="inherit"
        align="justify"
        variant="body2"
        className={classes.body2}
      >
        {lineText.split('\n').map((t, i) => {
          return <div key={i}>{t}</div>;
        })}
      </Typography>
      <Button
        color="default"
        variant="contained"
        size="large"
        className={classes.button}
        component="a"
        href="/login"
        onClick={() => {
          firebase.logout();
        }}
      >
        ログアウト  ＞
      </Button>
      {/* ) : (
        <></>
      )} */}
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
