import React, { useContext, useState, useEffect } from 'react';
import {
  IonCard,
  IonCardHeader,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFooter,
} from '@ionic/react';
import Copyright from '../components/Copyright';
import Box from '@material-ui/core/Box';
import withRoot from '../components/modules/withRoot';
// --- Post bootstrap -----
import ProductHero from '../components/modules/views/ProductHero';
import { AuthContext } from '../hooks/Auth';

const About = (props: any) => {
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    if (currentUser === null) {
      props.history.push('/Home');
      return;
    }
  }, [currentUser]);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">{/* <IonMenuButton /> */}</IonButtons>
          <IonTitle>ポケットコンサート公式サイト</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">ポケットコンサート公式サイト</IonTitle>
          </IonToolbar>
        </IonHeader>
        <React.Fragment>
          <ProductHero />
          <IonFooter className="ion-no-border">
            <IonToolbar>
              <Box mt={3}>
                <Copyright />
              </Box>
            </IonToolbar>
          </IonFooter>
        </React.Fragment>
      </IonContent>
    </IonPage>
  );
};

export default withRoot(About);
