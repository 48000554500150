import {
  IonButtons,
  IonContent,
  IonFooter,
  IonToast,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import firebase from '../hooks/firebase';
import Copyright from '../components/Copyright';
import { AuthContext } from '../hooks/Auth';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const PasswordRemind = (props: any) => {
  // const { currentUser } = useContext(AuthContext);
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isComposed, setIsComposed] = useState(false);
  const [toastIsShown, setToastIsShown] = useState(false);
  const showToast = () => {
    setToastIsShown(true);
  };
  const [message, setMessage] = useState('');

  useEffect(() => {
    const disabled = email === '';
    setDisabled(disabled);
    // if logged in, redirect to home
    // currentUser && props.history.push('/Teachers');
  }, [email]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>パスワード再設定</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">パスワード再設定</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component={'div'} variant="h5">
              パスワード再設定
            </Typography>
            <form className={classes.form} noValidate>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(e: any) => setEmail(e.target.value)}
                  onKeyDown={(e: any) => {
                    if (isComposed) return;

                    if (e.key === 'Enter') {
                      setEmail(e.target.value);
                      e.preventDefault();
                    }
                  }}
                  onCompositionStart={() => setIsComposed(true)}
                  onCompositionEnd={() => setIsComposed(false)}
                />
              </Grid>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={disabled}
                onClick={() => {
                  firebase
                    .sendPasswordResetEmail(email)
                    .then(function () {
                      // Email sent.
                      // console.dir('Email sent successfully');
                      props.history.push('/Login');
                      setToastIsShown(true);
                      setMessage(
                        'パスワード再設定用のEmailが送信されました。受信フォルダをご確認ください。',
                      );
                    })
                    .catch(e => {
                      // console.dir(e.code);
                      switch (e.code) {
                        default:
                          setToastIsShown(true);
                          setMessage(
                            '不明なエラーが発生しました。画面をリフレッシュしてもう一度お試しください。',
                          );
                          break;
                      }
                      // console.dir(e);
                    });
                }}
              >
                パスワード再設定
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link href="/login" variant="body2">
                    既にアカウントをお持ちの方は、ログイン
                  </Link>
                </Grid>
              </Grid>
            </form>
            <IonToast
              isOpen={toastIsShown}
              onDidDismiss={() => setToastIsShown(false)}
              message={message}
              duration={3000}
            />
          </div>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default PasswordRemind;
