// import Menu from './components/Menu';
//import Menu from './components/SignIn';
import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import About from './pages/About';
import NotVideo from './pages/NotVideo';
import { AuthProvider } from './hooks/Auth';
import Video from './pages/Video';
import PasswordRemind from './pages/PasswordRemind';
import { Terms } from './components/Terms';
import { Privacy } from './components/Privacy';
import { Law } from './components/Law';
import { Manual } from './components/Manual';
import Home from './pages/Home';

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        {/* <IonSplitPane contentId="main"> */}
        {
          <AuthProvider>
            {/* <Menu /> */}
            <IonRouterOutlet id="main">
              <Route path="/Login" component={Login} exact />
              <Route path="/SignUp" component={SignUp} exact />
              <Route path="/PasswordRemind" component={PasswordRemind} exact />
              <Route path="/terms" component={Terms} exact></Route>
              <Route path="/privacy" component={Privacy} exact></Route>
              <Route path="/law" component={Law} exact></Route>
              <Route path="/Home" component={Home} exact></Route>
              <Route path="/manual" component={Manual} exact></Route>
              <AuthProvider>
                <Route path="/about" component={About} exact />
                <Route path="/Video" component={Video} exact />
                <Route path="/NotVideo" component={NotVideo} exact />
              </AuthProvider>
              {/* <Redirect from="/" to="/Login" component={Login} exact /> */}
              <Redirect from="/" to="/Home" component={Home} exact />
            </IonRouterOutlet>
          </AuthProvider>
        }
        {/* </IonSplitPane> */}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
