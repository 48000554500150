import firebase from 'firebase';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database();
export const firestore = firebase.firestore();

const login = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

const googleLogin = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  return firebase.auth().signInWithPopup(provider);
};

const logout = () => {
  return firebase.auth().signOut();
};

const signUp = (email, password) => {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
};

const updateProfile = (user, updateObj) => {
  return user.updateProfile(updateObj);
};

const signUpFirestoreUser = user => {
  const data = {
    uid: user.uid,
    email: user.email,
    createdAt: user.uid,
    createdBy: new Date(),
    updatedAt: user.uid,
    updatedBy: new Date(),
  };
  return firebase.firestore().collection('users').add(data);
};

const updateFirestoreUser = (res, updateObj) => {
  return firebase.firestore().collection('users').doc(res.id).update(updateObj);
};

const sendPasswordResetEmail = email => {
  return firebase.auth().sendPasswordResetEmail(email);
};

const verifyEmail = () => {};

const currentUser = () => {
  return firebase.auth().currentUser;
};

const searchTeachers = () => {
  return firebase.firestore().collection('teachers').get();
};

const getPlans = () => {
  return firebase.firestore().collection('plans');
};

const createSubscription = () => {
  return firebase.functions().httpsCallable('createSubscription');
};

const createMeeting = () => {
  return firebase.functions().httpsCallable('createMeeting');
};

const newMeeting = () => {
  return firebase.functions().httpsCallable('newMeeting');
};

export const messagesRef = database.ref('messages');

const pushMessage = ({ name, text }) => {
  messagesRef.push({ name, text });
};
const getEvents = () => {
  return firebase.firestore().collection("events").get();
};

export default {
  login,
  googleLogin,
  logout,
  signUp,
  updateProfile,
  signUpFirestoreUser,
  updateFirestoreUser,
  sendPasswordResetEmail,
  currentUser,
  searchTeachers,
  getPlans,
  createSubscription,
  createMeeting,
  newMeeting,
  pushMessage,
  getEvents,
};
