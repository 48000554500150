import React from 'react';
import ReactMarkdown from 'react-markdown';
import { IonContent } from '@ionic/react';
import gfm from 'remark-gfm';

const markdown = `
> # チケットの購入手順
> ### ★手順１：ポケットコンサート公式サイトにユーザー登録
>
> 公式サイトのURL: https://pokecon.live
>
> ※ LINE公式アカウントの友達追加とは別に別途登録が必要になります。
>
>
> ### ★手順２：公式サイト内でログイン ➡️「チケット購入」ボタンよりチケットを購入
>
> ※ お支払い方法はクレジットカード、コンビニ決済、電子マネー等ご用意しております。
>
>
> 上記手順で公式サイトに登録とお支払いを済ませたら配信日を待つだけ！
>
> IDとパスワードは動画視聴に必要になりますので、必ず大切に保管してください。
>
> ※ 期間を過ぎますと視聴できなくなります。視聴を忘れてしまった場合も払い戻しはございませんので予めご了承ください。



> # 動画視聴手順
> ### ★手順１：ポケットコンサート公式サイトにログイン
>
> 公式サイトのURL: https://pokecon.live
>
> ### ★手順２：公式サイト内でログイン ➡️「動画視聴」ボタンより動画を視聴する
>
>「ポケットコンサート」は期間限定配信を行っておりますので、期間が過ぎてしまった場合は視聴できません。
>
> ※ チケットの購入がお済みで無い場合は視聴できません。
>
> ※ 入金済みの方で動画が視聴できない場合は、恐れ入りますがLINE公式アカウントまでお問い合わせください。
>
> LINE公式アカウントのURL: https://line.me/ti/p/@000eurwp
`;

export const Manual = () => {
  return (
    <IonContent>
      <ReactMarkdown allowDangerousHtml children={markdown} plugins={[gfm]} />
    </IonContent>
  );
};
