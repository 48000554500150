import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';

// const { REACT_APP_STRIPE_API_KEY } = process.env;

// export const stripeConfig = {
//   stripeApiKey: REACT_APP_STRIPE_API_KEY,
// };

// export const stripePromise = loadStripe(stripeConfig.stripeApiKey!);

ReactDOM.render(
  <React.StrictMode>
    {/* <Elements stripe={stripePromise}>  */}
       <App />
    {/* </Elements> */}
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
