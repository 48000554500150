import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonToast,
} from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from '../components/Copyright';
import { AuthContext } from '../hooks/Auth';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const NotVideo = (props: any) => {
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    if (currentUser === null) {
      props.history.push('/Home');
      return;
    }
  }, [currentUser]);
  const classes = useStyles();
  const text =
    '入金が確認できていないため\n動画視聴できません。\n入金済みの方は大変恐れ入りますが、LINE公式アカウントまで\nお問い合わせください。';
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>ポケットコンサート公式サイト</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">ポケットコンサート公式サイト</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component={'div'} variant="h5">
              {text.split('\n').map((t, i) => {
                return <div key={i}>{t}</div>;
              })}
            </Typography>
            <form className={classes.form} noValidate>
              <IonButton type="button" href="/About">
                メニュー画面に戻る
              </IonButton>
            </form>
          </div>
        </Container>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <Box mt={8}>
            <Copyright />
          </Box>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default NotVideo;
