import React from 'react';
import { IonContent, IonPage, IonToolbar, IonFooter } from '@ionic/react';
import Copyright from '../components/Copyright';

import withRoot from '../components/modules/withRoot';
// --- Post bootstrap -----
import ProductHero_startmenu from '../components/modules/views/ProductHero_startmenu';
import Box from '@material-ui/core/Box';

const Home = (props: any) => {
  return (
    <IonPage>
      {/* <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>ポケットコンサート公式サイト</IonTitle>
        </IonToolbar>
      </IonHeader> */}

      <IonContent>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">ポケットコンサート公式サイト</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <React.Fragment>
          <ProductHero_startmenu />
          {/* <ProductValues />
          <ProductHowItWorks />
          <ProductCTA /> */}
          {/* <ProductSmokingHero /> */}
          {/* <AppFooter /> */}
          <IonFooter className="ion-no-border">
            <IonToolbar>
              <Box mt={3}>
                <Copyright />
              </Box>
            </IonToolbar>
          </IonFooter>
        </React.Fragment>
      </IonContent>
    </IonPage>
  );
};

export default withRoot(Home);
