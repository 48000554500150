import { Typography } from '@material-ui/core';
import React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

const Copyright = () => {
  return (
    <Typography
      component={'div'}
      variant="body2"
      color="textSecondary"
      align="center"
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={2}>
          {/* <Link href="/login" variant="body1" > */}
          {/* <Link href="../components/modules/views/terms.md" variant="body1"> */}
          <Link
            href="/Terms"
            variant="body2"
            target="_blank"
            rel="noopener noreferrer"
          >
            利用規約
          </Link>
        </Grid>
        <Grid item xs={2}>
          {/* <Link href="/login" variant="body1" > */}
          {/* <Link href="../components/modules/views/terms.md" variant="body1"> */}
          <Link
            href="/Privacy"
            variant="body2"
            target="_blank"
            rel="noopener noreferrer"
          >
            プライバシーポリシー
          </Link>
        </Grid>
        <Grid item xs={2}>
          {/* <Link href="/login" variant="body1" > */}
          {/* <Link href="../components/modules/views/terms.md" variant="body1"> */}
          <Link
            href="/law"
            variant="body2"
            target="_blank"
            rel="noopener noreferrer"
          >
            特定商取引法に基づく表記
          </Link>
        </Grid>
      </Grid>
      {'Copyright © ポケットコンサート '}
      {/* <a
        color="inherit"
        // href="https://Pokecon.com/"
        target="_blank"
        rel="noopener noreferrer"
      > */}

      {/* {''} */}
      {/* </a> */}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default Copyright;
