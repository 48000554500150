import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonToast,
} from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import firebase from '../hooks/firebase';
import SignUp from './SignUp';
import Copyright from '../components/Copyright';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../hooks/Auth';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  home: {
    marginTop: theme.spacing(2),
    width: 120,
  },
}));

const Login = (props: any) => {
  const { currentUser } = useContext(AuthContext);
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isComposed, setIsComposed] = useState(false);
  const [toastIsShown, setToastIsShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    // if (currentUser) {
    //   props.history.push('/About');
    //   return;
    // }
    const disabled = email === '' || password === '';
    setDisabled(disabled);
    // if logged in, redirect to home
  }, [email, password]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>ポケットコンサート公式サイト</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">ポケットコンサート公式サイト</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component={'div'} variant="h5">
              ログイン画面
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="メールアドレス"
                name="email"
                autoFocus
                onChange={(e: any) => setEmail(e.target.value)}
                onKeyDown={(e: any) => {
                  if (isComposed) return;

                  if (e.key === 'Enter') {
                    setEmail(e.target.value);
                    e.preventDefault();
                  }
                }}
                onCompositionStart={() => setIsComposed(true)}
                onCompositionEnd={() => setIsComposed(false)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="パスワード"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e: any) => setPassword(e.target.value)}
                onKeyDown={(e: any) => {
                  if (isComposed) return;

                  if (e.key === 'Enter') {
                    setPassword(e.target.value);
                    e.preventDefault();
                  }
                }}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="パスワードを保存"
              /> */}
              <IonButton
                type="button"
                expand="block"
                disabled={disabled}
                onClick={e => {
                  firebase
                    .login(email, password)
                    .then(user => {
                      props.history.push('/About');
                    })
                    .catch(e => {
                      // console.dir(e.code);
                      setToastIsShown(true);
                      switch (e.code) {
                        case 'auth/invalid-email':
                          setToastIsShown(true);
                          setErrorMessage(
                            'メールアドレスまたはパスワードが誤っています。確認してください。',
                          );
                          break;
                        case 'auth/wrong-password':
                          setToastIsShown(true);
                          setErrorMessage(
                            'メールアドレスまたはパスワードが誤っています。確認してください。',
                          );
                          break;
                        case 'auth/user-not-found':
                          setToastIsShown(true);
                          setErrorMessage(
                            'メールアドレスまたはパスワードが誤っています。確認してください。',
                          );
                          break;
                        case 'auth/too-many-requests':
                          setToastIsShown(true);
                          setErrorMessage(
                            'メールアドレスまたはパスワードが誤っています。確認してください。',
                          );
                          break;
                        default:
                          setToastIsShown(true);
                          setErrorMessage(
                            '不明なエラーが発生しました。画面をリフレッシュしてもう一度お試しください。',
                          );
                          break;
                      }
                      // console.dir(e);
                    });
                }}
              >
                ログイン
              </IonButton>
              <IonButton
                type="button"
                expand="block"
                className={classes.home}
                onClick={e => {
                  props.history.push('/Home');
                }}
                // disabled={disabled}
              >
                ホームへ戻る
              </IonButton>
              {/*<Grid container>*/}
              <Grid item xs>
                <Link href="/passwordRemind" variant="body2">
                  {/*Forgot password?*/}
                  パスワードはお忘れですか？
                </Link>
              </Grid>
              {/*</Grid>*/}
              <Grid item>
                <Link href="/signup" variant="body2">
                  {/*"Don't have an account? Sign Up"*/}
                  アカウントはお持ちですか？アカウント登録はこちら
                </Link>
              </Grid>
            </form>
            <IonToast
              isOpen={toastIsShown}
              onDidDismiss={() => setToastIsShown(false)}
              message={errorMessage}
              duration={3000}
            />
          </div>
        </Container>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <Box mt={3}>
            <Copyright />
          </Box>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Login;
